<template lang="pug">
	.promotions
		div(v-if='item && isReady && item.canSeeMenuPlans()')
			project-alerts
			.d-flex.align-items-center.promotions__header
				h2.promotions__title {{ $t('promotions.promotionsInfo.tariffsAndPromotions') }}
				a(href="https://docs.paywall.pw/rus/monetizaciya-kontenta-i-soobshestv/tarify-i-akcii" target="_blank")
					svg.cursor-pointer(xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none")
						circle(cx="14" cy="14" r="14" :fill="isDarkColor ? '#27282A' : '#FFF'")
						path(d="M13.58 15.5867C13.58 15.3502 13.5924 15.1324 13.6173 14.9333C13.6547 14.7218 13.7231 14.5164 13.8227 14.3173C13.9347 14.1058 14.084 13.888 14.2707 13.664C14.4698 13.44 14.7311 13.1911 15.0547 12.9173C15.5773 12.4693 15.9756 12.0649 16.2493 11.704C16.5231 11.3431 16.66 10.9573 16.66 10.5467C16.66 9.94933 16.4609 9.45778 16.0627 9.072C15.6644 8.68622 15.1169 8.49333 14.42 8.49333C13.7231 8.49333 13.1756 8.68622 12.7773 9.072C12.3791 9.45778 12.18 9.94933 12.18 10.5467H10.5C10.5 10.0364 10.5871 9.56978 10.7613 9.14667C10.948 8.71111 11.2093 8.33778 11.5453 8.02667C11.8938 7.70311 12.3107 7.45422 12.796 7.28C13.2813 7.09333 13.8227 7 14.42 7C15.0173 7 15.5587 7.09333 16.044 7.28C16.5293 7.45422 16.94 7.70311 17.276 8.02667C17.6244 8.33778 17.8858 8.71111 18.06 9.14667C18.2467 9.56978 18.34 10.0364 18.34 10.5467C18.34 10.8951 18.284 11.2249 18.172 11.536C18.06 11.8471 17.9044 12.1458 17.7053 12.432C17.5187 12.7058 17.3009 12.9733 17.052 13.2347C16.8031 13.4836 16.548 13.72 16.2867 13.944C16.0751 14.1182 15.9009 14.2862 15.764 14.448C15.6271 14.5973 15.5151 14.7467 15.428 14.896C15.3533 15.0329 15.2973 15.176 15.26 15.3253C15.2351 15.4622 15.2227 15.6116 15.2227 15.7733V16.1467H13.58V15.5867ZM14.42 20.44C14.0591 20.44 13.748 20.3156 13.4867 20.0667C13.2378 19.8053 13.1133 19.4942 13.1133 19.1333C13.1133 18.76 13.2378 18.4489 13.4867 18.2C13.748 17.9511 14.0591 17.8267 14.42 17.8267C14.7809 17.8267 15.0858 17.9511 15.3347 18.2C15.596 18.4489 15.7267 18.76 15.7267 19.1333C15.7267 19.4942 15.596 19.8053 15.3347 20.0667C15.0858 20.3156 14.7809 20.44 14.42 20.44Z" fill="#52A7F9")
				.btn.btn-primary.plus-primary.promotions__btn-plus(v-if="tabPromotions == 3" @click="$bvModal.show('comebacker-form')")
					span.plus-primary

			b-tabs(nav-class='tabs-filled tabs-filled-transparent tabs-plans' v-model='tabPromotions' no-fade)
				//- TAB PLANS
				b-tab(:title="$t('promotions.tabs.plans')")
					tariff(@click="$bvModal.show('tariff-form')")
					.foreground-color.basic-box-shadow.border-rounded.mb-4.promotions__plans(v-if='!plans || plans.length == 0')
						h2.bold.mb-3 {{ $t('promotions.tariff.noTariffs.title') }}
						p.b3.m-0.text-grey.promotions__plans-text {{ $t('promotions.tariff.noTariffs.text') }}
					create-event.mb-4(v-if='!plans || plans.length == 0' :promotion="tabs.tariff" :text="$t('promotions.tariff.createEvent.btn')" @click="$bvModal.show('tariff-form')")

				//- TAB STOCK
				b-tab(:title="$t('promotions.tabs.stock')" lazy)
					stocks(@click="$bvModal.show('stock-form')")
					advantages.mb-4(
						v-if='!promotions || promotions.length == 0'
						:title="$t('promotions.stock.title')"
						:advantagesLeft="$t('promotions.stock.advantagesLeft')"
						:advantagesRight="$t('promotions.stock.advantagesRight')"
						:advantagesClass="'advantages--plans'")
					create-event.mb-4(v-if='!promotions || promotions.length == 0' :promotion="'stocks'" :text="$t('promotions.stock.createEvent.btn')" @click="$bvModal.show('stock-form')")

				//- TAB PROMO CODES
				b-tab(:title="$t('promotions.tabs.promoCodes')" lazy)
					promo-codes(@click="$bvModal.show('promo-code-form')" @editPromocode="keyPlans++")
					advantages.mb-4(
						v-if='!promocodes || promocodes.length == 0'
						:title="$t('promotions.promoCodes.title')"
						:advantagesLeft="$t('promotions.promoCodes.advantagesLeft')"
						:advantagesRight="$t('promotions.promoCodes.advantagesRight')"
						:advantagesClass="'advantages--plans advantages--promo-codes'"
					)
					create-event.mb-4(v-if='!promocodes || promocodes.length == 0' :promotion="'promo-codes'" :text="$t('promotions.promoCodes.createEvent.btn')" @click="$bvModal.show('promo-code-form')")
					//promotions-info(:page="'promotions'")

				//- TAB COMEBACKER
				b-tab(:title="$t('promotions.tabs.comebackers')" lazy)
					comebacker(v-if='comebackersList && comebackersList.length > 0' @click="$bvModal.show('comebacker-form')" @goToPlans="tabPromotions=0")
					advantages.mb-4(
						v-if='!comebackersList || comebackersList.length == 0'
						:title="$t('promotions.comebacker.title')"
						:advantagesLeft="$t('promotions.comebacker.advantagesLeft')"
						:advantagesRight="isMobile ? $t('promotions.comebacker.advantagesRightMobile') : $t('promotions.comebacker.advantagesRight')"
						:advantagesClass="'advantages--plans advantages--comebacker'"
					)
					create-event.mb-4(v-if='!comebackersList || comebackersList.length == 0' :promotion="'comebacker'" :text="$t('promotions.comebacker.createEvent.btn')" @click="$bvModal.show('comebacker-form')")

		.loading(v-else)

		modal-general(:id="'tariff-form'" :title="$t('promotions.tariff.createEvent.btn')" contentClass="tariff-modal")
			add-promotion(:create="tabs.tariff" :channels="channels" :modalId="'tariff-form'")

		modal-general(:id="'stock-form'" :title="$t('promotions.stock.createEvent.btn')" contentClass="tariff-modal")
			add-promotion(:create="tabs.stock" :plans="plansActive" :modalId="'stock-form'" @goToPlans='tabPromotions = 0')

		modal-general(:id="'promo-code-form'" :title="$t('promotions.promoCodes.createEvent.btn')" contentClass="tariff-modal")
			add-promotion(:create="tabs.promoCode" :plans="plansActive" :modalId="'promo-code-form'" @goToPlans='tabPromotions = 0' @addPromocode="keyPlans++")

		modal-general(:id="'comebacker-form'" :title="$t('promotions.comebacker.createEvent.btn')" contentClass="tariff-modal")
			add-promotion(:create="tabs.comebacker" :plans="plansAll" :modalId="'comebacker-form'" @goToPlans='tabPromotions = 0')
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ModalAddPlan from '@/components/Modals/AddPlan';
import ModalRemovePlan from '@/components/Modals/RemovePlan';
import PlanTable from '@/components/Tables/Plans';
import Advantages from '@/components/Cards/Advantages';
import CreateEvent from '@/components/Cards/CreateEvent';
import Tariff from '@/components/Tables/Tariff';
import Stocks from '@/components/Tables/Stocks';
import PromoCodes from '@/components/Tables/PromoCodes';
import Comebacker from '@/components/Tables/Comebacker';
import PromotionsInfo from '@/components/Cards/PromotionsInfo';
import ModalGeneral from '@/components/Modals/ModalGeneral';
import AddPromotion from '@/components/Form/AddPromotion';
import ProjectAlerts from '@/components/Cards/ProjectAlerts';
import { PLAN_TABS } from '@/models/project';

export default {
    name: 'ProjectPlans',
    props: ['id'],
    components: {
        'table-plans': PlanTable,
        ModalAddPlan,
        ModalRemovePlan,
        Advantages,
        CreateEvent,
        Tariff,
        Stocks,
        PromoCodes,
        Comebacker,
        PromotionsInfo,
        ModalGeneral,
        ProjectAlerts,
        AddPromotion,
    },
    metaInfo() {
        return { title: this.getTitle };
    },
    data: () => ({
        keyPlans: 0,
        tabPromotions: 0,
        itemAddPlan: null,
        itemRemovePlan: null,
        tabs: PLAN_TABS,
        isPromotionLoaded: false,
        isPromocodeLoaded: false,
    }),
    computed: {
        ...mapGetters({
            item: 'project/opened',
            promotions: 'project/promotions',
            promocodes: 'project/promocodes',
            plans: 'plans/list',
            planEdited: 'plans/edited',
            channels: 'resources/channels',
            groups: 'resources/groups',
            comebackersList: 'comebackers/items',
        }),
        getTitle() {
            if (this.item) {
                return this.item.title;
            }

            return 'Проект';
        },
        plansActive() {
            return _.filter(this.plans, p => p.isActive());
        },
        plansAll() {
            return _.filter(this.plans, p => !p.isPeriodOneTime());
        },
        resources() {
            return _.filter([...this.channels, ...this.groups], v => v.isActive());
        },
        isReady() {
            return this.isPromocodeLoaded && this.isPromotionLoaded;
        },
    },
    created() {
        this.goToDashboard();

        if (this.$route.query && this.$route.query.tabPromotions) {
            this.tabPromotions = this.$route.query.tabPromotions;
        }
        this.pageColor = this.item && this.item.isPaymentPageDark ? this.item.isPaymentPageDark : false;
    },
    mounted() {
        this.getPromotions({ project: this.id, itemsPerPage: 5000 })
            .then(v => {
                if (v.length > 0)
                    this.isEditablePromotions({ id: _.map(v, p => p.id) })
                        .then(t => {
                            this.isPromotionLoaded = true;
                        })
                        .catch(v => (this.isPromotionLoaded = true));
                else this.isPromotionLoaded = true;
            })
            .catch(v => (this.isPromotionLoaded = true));

        this.getPromocodes({ project: this.id, itemsPerPage: 5000 })
            .then(v => {
                if (v.length > 0)
                    this.isEditablePromocodes({ id: _.map(v, p => p.id) })
                        .then(t => {
                            this.isPromocodeLoaded = true;
                        })
                        .catch(v => (this.isPromocodeLoaded = true));
                else this.isPromocodeLoaded = true;
            })
            .catch(v => (this.isPromocodeLoaded = true));

        this.getComebackersList({ project: this.id });
    },
    methods: {
        ...mapActions({
            getPromotions: 'project/promotions',
            getPromocodes: 'project/promocodes',
            isEditablePromotions: 'project/isEditablePromotions',
            canBeDeletedPlans: 'plans/canBeDeleted',
            getComebackersList: 'comebackers/comebackersList',
        }),
        canAddPlan() {
            return this.plans.length < 10 || _.filter(this.plans, p => !p.isArchived()).length < 10;
        },
        openModalRemovePlan(i) {
            this.itemRemovePlan = null;

            this.$nextTick(() => {
                this.itemRemovePlan = i;
            });
        },
        openModalAddPlan() {
            this.$store.commit('plans/edited', {});
        },
        hideModalAddPlan() {
            this.$store.commit('plans/edited', null);
        },
        goToResources() {
            this.$router.push({ name: 'project_resources' });
        },
        goToDashboard() {
            if (this.item && this.item.id && !this.item.canSeeMenuPlans())
                return this.$router.push({ name: 'project_dashboard' });
        },
    },
    watch: {
        planEdited(nv) {
            this.itemAddPlan = nv;
        },
        item() {
            this.goToDashboard();
        },
        keyPlans() {
            this.getPromocodes({ project: this.id, itemsPerPage: 5000 })
                .then(v => {
                    if (v.length > 0)
                        this.isEditablePromocodes({ id: _.map(v, p => p.id) })
                            .then(t => {
                                this.isPromocodeLoaded = true;
                            })
                            .catch(v => (this.isPromocodeLoaded = true));
                    else this.isPromocodeLoaded = true;
                })
                .catch(v => (this.isPromocodeLoaded = true));
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.promotions {
    max-width: 940px;

    @include respond-below(xs) {
        margin-bottom: 100px;
    }

    &__title {
        margin-right: 10.5px !important;
    }

    &__header {
        margin-bottom: 18.5px;

        @include respond-below(xs) {
            margin-bottom: 13px;
        }
    }

    &__plans {
        padding: 30px 20px;

        @include respond-below(sm) {
            padding: 16px;
        }
    }

    &__plans-text {
        max-width: 521px;
    }

    &__btn-plus {
        display: none;

        @media (max-width: 375px) {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 28px;
            width: 28px;
            height: 28px;
            padding: 0;
            margin-left: auto;
            margin-top: -2px;
            border-radius: 6px !important;
        }

        .plus-primary {
            width: 14px;
            height: 14px;
            mask-size: cover;
        }
    }
}
</style>
