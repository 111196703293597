<template lang="pug">
	.border-rounded.foreground-color.promotions-card(v-if="card")
		.position-relative.w-100.d-flex.flex-column.align-items-start.justify-content-between.h-100
			.w-100
				.promotions-card__header
					span.b1.text-bold.promotions-card__name(v-if="card.title") {{ card.title }}
					span.b4.text-primary.promotions-card__discount(v-if="card.discount" :class="{'is-comebacker': promotions == types.comebacker}") -{{ card.discount }}%

				.promotions-card__inner-comebacks(v-if="promotions == types.comebacker")
					span.b3.border-rounded.promotions-card__comebacks(v-if="card.countComebacks && card.countComebacks > 0") {{ card.countComebacks }} {{ declOfComebacks(card.countComebacks) }}
					span.b3.text-grey.border-rounded.promotions-card__comebacks(v-if="card.countComebacks == 0") {{ $t('promotions.noComebacks') }}
					span.b3.border-rounded.promotions-card__time(v-if="card.status == 'ACTIVE'") {{ card.getTimeFromMins() }}
					p.text-grey.mb-0.overflow-wrap.promotions-card__txt {{ card.description }}

				.promotions-card__inner(v-if="promotions != types.tariff && promotions != types.comebacker")
					span.b3.text-grey.text-nowrap.promotions-card__period(v-if="card.startAt && promotions != types.tariff") {{ $t('promotions.from') }} {{ card.startAt.split('T')[0] | dateDDMMMYYDots }}
					span.b3.text-grey.text-nowrap.promotions-card__period(v-if="card.endAt && promotions != types.tariff") {{ $t('promotions.to') }} {{ card.endAt.split('T')[0] | dateDDMMMYYDots }}
					span.b3.mr-3.text-grey(v-if="!card.startAt && !card.endAt && promotions != types.tariff") {{ $t('promotions.noTimeLimit') }}
					router-link.b3.text-primary.text-nowrap.promotions-card__subscriptions(
						v-if="card.subscriptionsCount && promotions == types.stock"
						:to='{name: "project_followers", query: {promotion: card.id}}'
						target='_blank') {{ card.subscriptionsCount }} {{ declOfSubscriptions(card.subscriptionsCount) }}
					span.b3.text-grey.text-nowrap.promotions-card__subscriptions(v-if="!card.subscriptionsCount && promotions == types.stock") {{ $t('promotions.noSubscriptions') }}
					router-link.text-primary.align-items-center.text-nowrap.promotions-card__subscriptions.promotions-card__usage(
						v-if="promotions == types.promoCode"
						:to='{name: "project_followers", query: {promoCode: card.id}}'
						target='_blank')
						span {{ $t('promotions.usage') }} {{ card.subscriptionsCount }}
						span(v-if="card.capacity != null") /{{ card.capacity + card.subscriptionsCount }}

				.d-flex.align-items-center.flex-wrap(v-if="promotions == types.tariff")
					.d-flex.align-items-end.text-grey.mr-10.mb-10
						span.h3.text-bold.text-primary(v-html="`${card.price.price()}&nbsp;`")
						span.h3.text-bold.text-primary(v-html='card.price.currencyTitle()')
						span.b3.promotions-card__term(v-html="`&nbsp;${$t('plan.periods.' + card.period.title)}`")
					span.text-primary.position-relative.mb-10.promotions-card__free(v-if="card.isTrial && card.free" v-html="`${$t('promotions.tariff.free')}&nbsp;${$t('plan.freePeriods.' + card.free.title)}`")
			
			span.b3.text-grey.promotions-card__description(v-if="promotions == types.tariff && card.description" v-html="card.description")
			
			dropdown-plans.promotions-card__dropdown(v-if="promotions != types.tariff" :discount="card.discount" :list="card.plans" :id="card.id" :type="promotions")
			users-pics(v-else-if='card.hasActiveResources()' :list="card.getResources()" :card='card')
			.alerts.alert-error.promotions-card__alert(v-else)
				span {{ $t('promotions.tariff.withoutResources') }}
				.cursor-pointer.promotions-card__alert-icon(:id="!isMobile ? `resourceTooltip-${card.id}` : ''" @click="isMobile ? $bvModal.show(`resourceTooltip-${card.id}`) : ''")
					svg(width='24' height='25' viewbox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg')
						circle(cx='12' cy='12.5' r='12' fill='#FF7C7C')
						path(d='M11.2204 6.20996H12.7796V14.7144H11.2204V6.20996ZM12 18.7895C11.6575 18.7895 11.3622 18.6714 11.1141 18.4351C10.8779 18.1871 10.7598 17.8918 10.7598 17.5492C10.7598 17.1949 10.8779 16.8996 11.1141 16.6634C11.3622 16.4271 11.6575 16.309 12 16.309C12.3425 16.309 12.6319 16.4271 12.8682 16.6634C13.1162 16.8996 13.2402 17.1949 13.2402 17.5492C13.2402 17.8918 13.1162 18.1871 12.8682 18.4351C12.6319 18.6714 12.3425 18.7895 12 18.7895Z' :fill="isDarkColor ? '#27282A' : '#FFFFFF'")

				modal-tooltip(
					:id="`resourceTooltip-${card.id}`"
					:title="$t('promotions.tariff.tariffNotAvailable')"
					:text="$t('promotions.tariff.withoutResourcesDesc')"
					:textMobile="$t('promotions.tariff.withoutResourcesDesc')"
				)

			svg.d-block.ml-auto.promotions-card__dots(@click="onOpenPopover" :id="`${card.title}-${card.id}`" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none")
				g(fill="#52A7F9" clip-path="url(#a)")
					path(d="M1.5 10.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM9 10.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM16.5 10.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z")
						defs
							clipPath (id="a")
								path(fill="#fff" d="M0 0h18v18H0z")

			b-popover(ref="popoverMenuDots" :target="`${card.title}-${card.id}`" placement="left" :custom-class="customClass")
				i.fontello-btn.fontello-btn-primary.fontello-icon-copy(v-if="promotions == types.promoCode && !card.isArchived()" @click="copy(card.title), onClosePopover()")
				i.fontello-btn.fontello-btn-primary.fontello-icon-edit(@click="openModal(promotions)")
				i.fontello-btn.fontello-btn-red.fontello-icon-trash(v-if='!card.isArchived()' @click='openModalRemove(promotions)')

		modal-remove-promotion(
			v-if="promotions == types.tariff"
			:btnClass="getBtnTariff()"
			:id="`tariff-${card.id}`"
			:title="isRemoveTariff ? $t('promotions.tariff.delete.title1') : $t('promotions.tariff.delete.title')"
			:text="getTextTariff(card)"
			:btnText="getTxtBtnTariff()"
			:item='removeItem')

		modal-remove-promotion(
			v-if="promotions == types.stock"
			:btnClass="card.canBeDeleted ? 'btn-peril' : 'btn-primary'"
			:id="`stock-${card.id}`"
			:title="$t('promotions.stock.delete.title')"
			:text="getTextStock(card)"
			:btnText="card.canBeDeleted ? $t('promotions.stock.delete.btnText') : $t('promotions.stock.archive.btnText')"
			:item='removeItem')

		modal-remove-promotion(
			v-if="promotions == types.promoCode"
			:btnClass="card.canBeDeleted ? 'btn-peril' : 'btn-primary'"
			:id="`promoCodes-${card.id}`"
			:title="$t('promotions.promoCodes.delete.title')"
			:text="card.canBeDeleted ? $t('promotions.promoCodes.delete.text') : (card.subscriptionsCount > 0 ? $t('promotions.promoCodes.archive.text') : $t('promotions.promoCodes.archive.textIfActive'))"
			:btnText="card.canBeDeleted ? $t('promotions.promoCodes.delete.btnText') : $t('promotions.promoCodes.archive.btnText')"
			:item='removeItem')

		modal-remove-promotion(
			v-if="promotions == types.comebacker"
			:btnClass="card.canBeDeleted ? 'btn-peril' : 'btn-primary'"
			:id="`comebacker-${card.id}`"
			:title="$t('promotions.comebacker.delete.title')"
			:text="card.canBeDeleted  ? $t('promotions.comebacker.delete.text') : (card.countComebacks === 0 && !card.canBeDeleted ?  $t('promotions.comebacker.archive.textActive'): $t('promotions.comebacker.archive.text'))"
			:btnText="card.canBeDeleted ? $t('promotions.comebacker.delete.btnText') : $t('promotions.comebacker.archive.btnText')"
			:item='removeItem')

		modal-general(v-if="promotions == types.tariff" :id="`edit-tariff-${card.id}`" :title="$t('promotions.tariff.editEvent.btn')")
			add-promotion(:create="types.tariff" :item="selectedItem" :channels="card.getResources()" :modalId="`edit-tariff-${card.id}`")

		modal-general(v-if="promotions == types.stock" :id="`edit-stock-${card.id}`" :title="$t('promotions.stock.editEvent.btn')")
			add-promotion(:create="types.stock" :item="selectedItem" :plans="activePlans" :modalId="`edit-stock-${card.id}`")

		modal-general(v-if="promotions == types.promoCode" :id="`edit-promo-code-${card.id}`" :title="$t('promotions.promoCodes.editEvent.btn')")
			add-promotion(:create="types.promoCode" :item="selectedItem" :plans="activePlans" :modalId="`edit-promo-code-${card.id}`" @editPromocode="$emit('editPromocode')")

		modal-general(v-if="promotions == types.comebacker" :id="`edit-comebacker-${card.id}`" :title="$t('promotions.comebacker.editEvent.btn')")
			add-promotion(:create="'comebacker'" :item="selectedItem" :plans="allPlans" :modalId="`edit-comebacker-${card.id}`" @goToPlans="$emit('goToPlans')")
</template>

<script>
import DropdownPlans from '@/components/Dropdown/DropdownPlans';
import RemovePromotion from '@/components/Modals/RemovePromotion';
import ModalGeneral from '@/components/Modals/ModalGeneral';
import AddPromotion from '@/components/Form/AddPromotion';
import UsersPics from '@/components/Common/UsersPics';
import ModalTooltip from '@/components/Modals/ModalTooltip';
import IconClose from '@/components/Common/IconClose';
import { PLAN_TABS } from '@/models/project';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'PromotionsCard',
    components: {
        DropdownPlans,
        'modal-remove-promotion': RemovePromotion,
        ModalGeneral,
        AddPromotion,
        UsersPics,
        IconClose,
        ModalTooltip,
    },
    props: {
        card: {
            default: () => {},
            type: Object,
        },
        promotions: {
            default: '',
            type: String,
        },
        customClass: {
            default: '',
            type: String,
        },
    },
    data: () => ({
        overlay: false,
        selectedItem: null,
        removeItem: null,
        types: PLAN_TABS,
        isHaveComebacker: true,
        isRemoveTariff: false,
        customClassCard: '',
    }),
    computed: {
        ...mapGetters({
            project: 'project/opened',
            plans: 'plans/list',
        }),
        activePlans() {
            return _.filter(this.plans, p => p.isActive());
        },
        allPlans() {
            return _.filter(this.plans, p => !p.isPeriodOneTime());
        },
    },
    created() {
        window.addEventListener('click', e => {
            if (this.overlay === true) {
                const target = e.target;

                if (!target.closest('.b-popover') && !target.closest('.promotions-card__dots')) {
                    this.onClosePopover();
                }
            }
        });

        this.customClassCard = this.customClass;

        if (this.card.capacity) {
            this.customClassCard = 'popover-usage';
        }
    },
    methods: {
        ...mapActions({
            getCanBeDeleted: 'project/canBeDeleted',
        }),
        /* Функция правильного склонения слов */
        declOfNum(n, text_arr) {
            let num = Math.abs(n) % 100;
            let n1 = num % 10;

            if (num > 10 && num < 20) {
                return text_arr[2];
            }
            if (n1 > 1 && n1 < 5) {
                return text_arr[1];
            }
            if (n1 == 1) {
                return text_arr[0];
            }
            return text_arr[2];
        },
        declOfSubscriptions(num) {
            return this.declOfNum(num, [
                this.$t('promotions.declOfSubscriptions[0]'),
                this.$t('promotions.declOfSubscriptions[1]'),
                this.$t('promotions.declOfSubscriptions[2]'),
            ]);
        },
        declOfComebacks(num) {
            return this.declOfNum(num, [
                this.$t('promotions.declOfComebacks[0]'),
                this.$t('promotions.declOfComebacks[1]'),
                this.$t('promotions.declOfComebacks[2]'),
            ]);
        },
        getScrollbarWidth() {
            // Creating invisible container
            const outer = document.createElement('div');
            outer.style.visibility = 'hidden';
            outer.style.overflow = 'scroll'; // forcing scrollbar to appear
            outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
            document.body.appendChild(outer);

            // Creating inner element and placing it in the container
            const inner = document.createElement('div');
            outer.appendChild(inner);

            // Calculating difference between container's full width and the child width
            this.scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

            // Removing temporary elements from the DOM
            outer.parentNode.removeChild(outer);
        },
        onOpenPopover() {
            if (!this.isMobile) {
                this.getScrollbarWidth();
                document.querySelector('body').style.paddingRight = this.scrollbarWidth + 'px';
            }

            if (this.$refs.popoverMenuDots) this.$refs.popoverMenuDots.$emit('open');

            document.body.classList.add('overlay');
            this.overlay = true;
        },
        onClosePopover(id) {
            if (!this.isMobile) {
                document.querySelector('body').style.paddingRight = 0;
            }

            if (this.$refs.popoverMenuDots) this.$refs.popoverMenuDots.$emit('close');

            document.body.classList.remove('overlay');
            this.overlay = false;
            this.$root.$emit('bv::show::modal', id);
        },
        openModal(promotion) {
            this.getCanBeDeleted({ id: this.project.id });
            this.selectedItem = _.cloneDeep(this.card);
            this.$bvModal.show(`edit-${promotion}-${this.card.id}`);
            this.onClosePopover(`edit-${promotion}-${this.card.id}`);
        },
        openModalRemove(promotion) {
            this.removeItem = _.cloneDeep(this.card);
            this.onClosePopover(`${promotion}-${this.card.id}`);
        },
        async copy(text) {
            try {
                await this.$copyText(text);
                this.$notify('success', `😊 &nbsp;&nbsp;&nbsp;${this.$t('promotions.copy')}`);
            } catch (e) {
                console.log(e);
            }
        },
        getTextStock(card) {
            if (card.canBeDeleted) {
                return this.$t('promotions.stock.delete.text');
            } else if (card.subscriptionsCount > 0) {
                return this.$t('promotions.stock.archive.text');
            } else {
                return this.$t('promotions.stock.archive.textIfActive');
            }
        },
        getTextTariff(card) {
            if (card?.comebacker && this.isRemoveTariff) {
                return `${this.$t('promotions.tariff.delete.text3')} <span class='text-link'>${card.comebacker.title}</span>${this.$t('promotions.tariff.delete.text4')}`;
            }
            if (card?.comebacker && this.isHaveComebacker) {
                if (this.card.canBeDeleted) {
                    return `${this.$t('promotions.tariff.delete.text1')} <span class='text-link'>${card.comebacker.title}</span>${this.$t('promotions.tariff.delete.text2')}`;
                } else if (card.subscriptionsCount > 0) {
                    return `${this.$t('plan.archivePlanStayComebacker')} <span class='text-link'>${card.comebacker.title}</span>.`;
                } else {
                    return `${this.$t('plan.archivePlanStayComebackerActive')} <span class='text-link'>${card.comebacker.title}</span>.`;
                }
            } else {
                if (this.card.canBeDeleted) {
                    return this.$t('promotions.tariff.delete.text');
                } else if (card.countSubscriptions > 0) {
                    return this.$t('plan.archivePlanConfirm');
                } else {
                    return this.$t('plan.archivePlanConfirmActive');
                }
            }
        },
        getBtnTariff() {
            if (this.isRemoveTariff) {
                return 'btn-primary';
            } else if (this.card.canBeDeleted) {
                return 'btn-peril';
            } else {
                return 'btn-primary';
            }
        },
        getTxtBtnTariff() {
            if (this.isRemoveTariff) {
                return this.$t('wizard.continue');
            } else if (this.card.canBeDeleted) {
                return this.$t('promotions.tariff.delete.btnText');
            } else {
                return this.$t('forms.archive');
            }
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.promotions-card {
    padding: 19px 20px;
    width: calc(50% - 10px);
    box-shadow: 0px 2px 20px 0px rgba(#000000, 0.05);

    @include respond-below(sm-new) {
        width: 100%;
    }

    @include respond-below(xs) {
        padding: 20px;
    }

    &__header {
        margin-bottom: 11px;
        width: calc(100% - 35px);

        @include respond-below(xs) {
            margin-bottom: 5px;
        }
    }

    &__name {
        overflow-wrap: anywhere;
    }

    &__alert {
        margin-top: 20px;
        padding: 12px 20px;
        min-height: auto;
        font-size: 14px;
        line-height: 22px;

        @include respond-below(xs) {
            margin-top: 10px;
        }

        &-icon {
            margin-left: 10px;
        }
    }

    &__dots {
        position: absolute;
        top: 4px;
        right: 4px;
        cursor: pointer;

        @include respond-below(xs) {
            top: 0;
            right: 0;
        }
    }

    &__discount {
        padding: 3.5px 7px;
        background-color: var(--primary-bg-color);
        border-radius: 6px;
        margin-left: 10px;

        &.is-comebacker {
            background-color: var(--brand-bg-discount);
            color: #ac7dd1 !important;
        }
    }

    &__free {
        font-weight: 500;
        padding: 5px 11px 5px 38px;
        font-size: 12px;
        line-height: 18px;
        background: var(--primary-bg-color);
        border-radius: var(--border-radius-rounded);

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 11px;
            width: 18px;
            height: 18px;
            background-image: url('/assets/img/icons/clock.svg');
            transform: translateY(-50%);
        }
    }

    &__period {
        margin-right: 4px;
    }

    &__subscriptions {
        margin-left: 12px;

        @media (max-width: 350px) {
            margin-left: 0;
            width: 100%;
            display: block;
            margin-top: 14px;
        }
    }

    &__usage {
        @include respond-below(xs) {
            margin-left: 0;
            width: 100%;
            display: block;
            margin-top: 14px;
            margin-bottom: 2px;
        }
    }

    &__inner {
        margin-bottom: 19px;

        @include respond-below(xs) {
            margin-top: 7px;
        }

        &-comebacks {
            margin-top: 5px;
            margin-bottom: 19px;

            @include respond-below(xs) {
                margin-top: 12px;
            }
        }
    }

    &__term {
        display: block;
        margin-bottom: 1.5px;
    }

    &__comebacks {
        color: #ac7dd1;
        padding: 7px 9.5px;
        border: 1px solid var(--grayscale-dark-line);
        margin-right: 11px;
    }

    &__time {
        background-color: var(--brand-bg-warning);
        color: var(--brand-color-warning);
        padding: 7px 9.5px;
    }

    &__txt {
        line-height: 16.8px;
        margin-top: 15px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        @include respond-below(xs) {
            margin-top: 18px;
            -webkit-line-clamp: 3;
        }
    }

    .users-pics {
        margin-top: 7px;
    }

    &__description {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        margin-bottom: 10px;

        @include respond-below(xs) {
            margin-bottom: 1px;
        }
    }
}

.dropdown-general .dropdown-toggle {
    font-size: 14px;
}
</style>
